import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnRelationLink from './columnRelationLink'
import useRouteParam from '../../hooks/useRouteParam'
import Introduction013 from './Introduction013'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0006 = (props: Props): JSX.Element => {
  const routeParam1 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0007/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0006'
  )

  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0018/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0006'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0019/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0006'
  )
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0010/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0006'
  )
  const routeParam5 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0008/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0006'
  )
  const routeParam6 = useRouteParam('/column/details/C0073/')
  const routeParam7 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="通常の労働時間制（固定労働時間制度）と変形労働時間制とは？" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.04.15</p>
      <section>
        <img src="/images/column/details/c0006.jpg" alt="キンクラコラム" />
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          <span>通常の労働時間制、フレックスタイム制、裁量労働制</span>
          など、我が国には働き方の多様化に対応した変則的な労働時間制が複数設けられています。どの制度を活用することでメリットの最大化を図ることができるかは、前提としておさえておくべき論点が明確化されていることで正しい選択が可能となります。
          <br />
          今回は、通常の労働時間制やいわゆる変則的な労働時間管理を俯瞰できるよう解説してまいります。
        </p>
        <div className="toc">
          <p>目次</p>
          <ol>
            <li>
              <a href="#toc1">通常の労働時間制</a>
            </li>
            <li>
              <a href="#toc2">フレックスタイム制</a>
            </li>
            <li>
              <a href="#toc3">裁量労働制</a>
            </li>
            <li>
              <a href="#toc4">変形労働時間制</a>
            </li>
            <li>
              <a href="#toc5">1か月単位の変形労働時間制</a>
            </li>
            <li>
              <a href="#toc6">1年単位の変形労働時間制</a>
            </li>
            <li>
              <a href="#toc7">1週間単位の変形労働時間制</a>
            </li>
            <li>
              <a href="#toc8">最後に</a>
            </li>
          </ol>
        </div>
        <div id="toc1">
          <h1>通常の労働時間制</h1>
        </div>
        <p>
          例えば月曜日から金曜日の8時30分から17時30分の時間帯が働くべき日であり、土日祝日が休みのような会社は通常の労働時間制を採用していることが多いでしょう。これは、最もオーソドックスな労務管理となり、人事労務担当者が管理しやすいというメリットがあります。
          <br />
          <br />
          注意点として、特定の日に残業が予想される場合であっても
          <span>
            実際に残業（1日8時間超、週40時間超）が発生した場合には割増賃金の支払いを回避できない
          </span>
          ことがデメリットとなります。
        </p>
        <ColumnRelationLink
          link={routeParam1}
          label="通常の労働時間制（固定労働時間制度）を採用するメリットや注意点は？"
        />
        <div id="toc2">
          <h1>フレックスタイム制</h1>
        </div>
        <p>
          端的には従業員に始業および終業時刻の決定を委ねることで、
          <span>
            月あたりの総労働時間を超えなければ、割増賃金が発生しない制度
          </span>
          です。ただし、1か月超のフレックスタイム制を採用する場合は1か月以内のフレックスタイム制では割増賃金の支払い対象とはなっていなかった「週50時間を超過した場合」も割増賃金の支払い対象となります。
          <br />
          <br />
          フレックスタイム制は、働き方の多様化が尊重されはじめた近年では導入に踏み切る企業も増えており、メリットとして一定の求人効果があると示唆されています。デメリットは特定の時間帯に従業員数が確保できないなどの声があります。この点についてはコアタイム（働くべき時間）を設定することでデメリットは限定的になるでしょう。
        </p>
        <ColumnRelationLink
          link={routeParam2}
          label="フレックスタイム制はどんな制度？導入のメリットとポイント"
        />
        <ColumnRelationLink
          link={routeParam3}
          label="自由度の高い新しい働き方「スーパーフレックス制度」とは？"
        />
        <div id="toc3">
          <h1>裁量労働制</h1>
        </div>
        <p>
          裁量労働制には2種類の制度があり、そのうちの1つの専門業務型裁量労働制は
          <span>厚生労働省令で定められた特定の業種に限って採用可能</span>
          です。そして、企画業務型裁量労働制は企業の中枢を担う
          <span>
            労働者に対して設けられた制度で、企画、立案、調査および分析に関する業務であることが導入の前提となる職種であり、かつ、実際に導入するには労使委員会の委員の5分の4以上の議決が必要
          </span>
          となります。
          <br />
          <br />
          裁量労働制のメリットは働いた時間と賃金がイコールとなるものではなく、3時間しか働いていない日でも賃金の減額はありません。これは創造的な業種であるがゆえに事業主からの指揮監督などがなじまないからです。デメリットとしては特に企画業務型裁量労働制では定期報告など、通常の労働時間制では発生しない（所轄労働基準監督署への）事務手続き（例えば労働者の健康および福祉を確保するための措置の実施状況）があり、管理が煩雑となる点です。
        </p>
        <ColumnRelationLink
          link={routeParam4}
          label="裁量労働制とはどんな制度？内容やメリット・デメリットを解説"
        />
        {/** ここに9パターンの広告設定する ※この記事は固定表示なので全部同じものを指定しています。 */}
        {props.no === 0 && <Introduction013 />}
        {props.no === 1 && <Introduction013 />}
        {props.no === 2 && <Introduction013 />}
        {props.no === 3 && <Introduction013 />}
        {props.no === 4 && <Introduction013 />}
        {props.no === 5 && <Introduction013 />}
        {props.no === 6 && <Introduction013 />}
        {props.no === 7 && <Introduction013 />}
        {props.no === 8 && <Introduction013 />}
        <div id="toc4">
          <h1>変形労働時間制</h1>
        </div>
        <p>
          変形労働時間制には以下の3つの制度があり、順に解説してまいります。
          <br />
          ・1か月単位の変形労働時間制
          <br />
          ・1年単位の変形労働時間制
          <br />
          ・1週間単位の変形労働時間制
        </p>
        <ColumnRelationLink
          link={routeParam5}
          label="多様な働き方、変形労働時間制とは？採用するメリットとは"
        />
        <div id="toc5">
          <h1>1か月単位の変形労働時間制</h1>
        </div>
        <p>
          <span>
            「1か月以内」の一定期間を平均して週の労働時間の平均が法定労働時間を超えないこと
          </span>
          を条件に特定の週や日に法定労働時間を超えて労働させることが可能となる制度です。
          <br />
          運用事例としては夜勤がある業種や月末または月初に限って繁忙となる業種に多く採用されています。後者については月末または月初に多く働いてもらうために月の中途の労働時間を減らすという労務管理手法が取られています。
          <br />
          <br />
          採用するにあたっての手続きは就業規則または労使協定の締結（所轄労働基準監督署への届出必要）となります。
        </p>
        <div id="toc6">
          <h1>1年単位の変形労働時間制</h1>
        </div>
        <p>
          「1か月を超えて1年以内」の一定期間を平均し1週間の平均が「40時間」を超えていなければ特定の週や日に法定労働時間を超えて働かせることが可能となる制度です。
          <br />
          運用事例としてはテーマパークなど月の中でというよりも
          <span>特定の季節に繁忙期を迎える業種</span>に多く採用されています。
          <br />
          <br />
          採用するにあたっての手続きは
          <span>
            就業規則に規定を設け、かつ、労使協定の締結が必要です。また、締結するだけでなく届出まで必要
          </span>
          です。
        </p>
        <div id="toc7">
          <h1>1週間単位の変形労働時間制</h1>
        </div>
        <p>
          そもそも採用できる業種に制限があり、労働者数が30人未満の小売業、旅館、料理店、飲食店が対象です。1週間の労働時間を40時間以内と定めれば1日10時間までの労働が可能となります。
          <br />
          <br />
          採用するにあたっての手続きは
          <span>就業規則に規定を設け、労使協定の締結および届出も必要</span>
          です。
        </p>
        <a href={routeParam6} className="column-detail-link">
          参照記事：変形労働時間制とは？勤怠管理の効率的な方法を解説
        </a>
        <div id="toc8">
          <h1 className="read">最後に</h1>
        </div>
        <p>
          各労働時間制共通の論点として、全ての割増賃金を回避できる制度は皆無です。例えば1か月フレックスタイム制であれば総労働時間が1か月の総枠を超えた場合には割増賃金が発生します。裁量労働制は対象業種であることが前提となりますが、フレックスタイム制と共通する部分として深夜割増（22時～翌朝5時の勤務）は適用されます。変形労働時間制についても深夜、休日割増の適用は免れられません。
          <br />
          <br />
          <span>
            どの労働時間制を採用するにあたってもどのような場合に割増賃金が発生するのかは必ずおさえておくべき論点であり、定期的に見直しが必要となります。
          </span>
          <br />
          <br />
          尚、民法改正に伴い、現在は残
          <span>業代の時効が5年（当分の間3年）に延長</span>
          されました。よって、残業代が発生するロジックを再確認し、中間管理職にも情報を共有することが重要です。特に労働者数が一定数以上いる場合は、定期的な見直しの重要度は極めて高いということです。
        </p>
        <a href={routeParam7} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0006
